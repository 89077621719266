import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";

import CommonHeader from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import UseCaseSider from "../../components/useCases/use-cases-sider";

const UseCaseRemoteWorking = ({}) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Week Planner</title>
      <meta
        name="description"
        content="If you need a tool to help you work remotely, a DayViewer Team Room will help keep this efficient, easy and simple. Manage tasks in your remote team and keep things centralized with a DayViewer Team Calendar Planner"
      />
    </Helmet>
    <Layout>
      <CommonHeader />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <UseCaseSider />

        <Layout className="guide-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>Week Planner</h1>

            <Button className="homepage-signup-btn">
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                Sign Up
              </a>
            </Button>
          </div>

          <Row>
            <div>
              <h2>Our Fast Planner System Makes Week Planning Easy</h2>
            </div>
            <Col>
              <p>
                Planning shouldn't be a difficult task, so we have developed a
                system that makes week planning simpler.
              </p>

              <p>
                The DayViewer Fast Planner system is a week planner view, which
                you create a task or other entry type and drag it into the
                planner to set it into the day of the week of your choice.
              </p>

              <p>
                This intuitive week planner system makes it perfect for
                scheduling timetables and classes, and is great for at-a-glance
                viewing of your week.
              </p>
              <p>
                Each day will show a mini timeline of your day, so everything
                can be seen clearly, you can simply click on a task to view more
                details or edit it.
              </p>

              <p>
                Give our week planner system to try, here is a short animation
                of how it works.
              </p>

              <div
                style={{
                  textAlign: "center",
                  padding: 20,
                }}
              >
                <img
                  src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/features/fastplanner.gif"
                  alt="week planner"
                  title="week planner"
                />
              </div>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default UseCaseRemoteWorking;
